<template>
  <b-row
    :id="`calendario${_uid}`"
    align-v="center"
    no-gutters
    style="position: relative;"
    @keyup.esc.prevent.stop="cancelarEdicao"
  >
    <b-col cols="12">
      <b-input-group>
        <template
          v-if="mostrarIcone"
          #append
        >
          <b-input-group-text :class="{'disabled-icon': disabled}">
            <strong class="text-primary">
              <font-awesome-icon
                :icon="faCalendarAlt"
                @click.prevent="mostrarCalendario()"
              />
            </strong>
          </b-input-group-text>
        </template>
        <masked-input
          :id="`inputcalendario_input${_uid}`"
          ref="inputcalendario"
          v-model="valueInner"
          class="form-control input-md"
          :placeholder="placeholder"
          :class="{'is-invalid' : invalid}"
          :mask="[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
          :disabled="disabled"
          @click.stop="abrirCalendario = true"
          @blur="alterarData(valueInner, false)"
          @keyup.enter.prevent.stop="alterarData(valueInner, false)"
        />
      </b-input-group>
      <b-popover
        v-if="abrirCalendario"
        :id="`popoverData${_uid}`"
        triggers="manual"
        :target="`inputcalendario_input${_uid}`"
        :container="`inputcalendario${_uid}`"
        placement="bottomright"
        :disabled="!abrirCalendario"
        :show.sync="abrirCalendario"
      >
        <div>
          <vue-date-picker
            inline
            style="border: none;"
            :language="lang"
            :value="newDueDate"
            :calendar-class="'input-calendar'"
            :disabled-dates="disabledDates"
            @selected="alterarData($event, true)"
          />
        </div>
      </b-popover>
    </b-col>
  </b-row>
</template>

<style lang="scss" src="./style.scss"></style>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { ptBR } from 'vuejs-datepicker/dist/locale'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'CalendarioInput',
  components: {
    FontAwesomeIcon,
    'vue-date-picker': Datepicker,
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    mostrarIcone: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Alterar data',
    },
    disabledDates: {
      type: Object,
      default: () => { },
    },
    value: {
      type: [String, Date],
      default: () => null,
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      faCalendarAlt,
      abrirCalendario: this.opened,
      valueInner: this.value,
      lang: ptBR,
    }
  },
  computed: {
    newDueDate() {
      if (this.valueInner) {
        return this.formatarInputPara(true)
      }
      return this.value
    },
  },
  watch: {
    value(value, oldValue) {
      if (value !== oldValue) {
        this.valueInner = value
      }
    },
  },
  created() {
    if (this.autofocus) {
      this.focus()
    }
  },
  methods: {
    alterarData(date, calendar) {
      if (!date) {
        this.limparData()
      } else {
        const newDate = !calendar ? moment(this.formatarInputPara(false)).toDate() : date
        this.valueInner = moment(newDate).format('DD/MM/YYYY')
        this.$emit('input', moment(newDate).format('DD/MM/YYYY'))
        this.$emit('change', moment(newDate).toISOString())
      }
      this.abrirCalendario = false
    },
    limparData() {
      this.valueInner = ''
      this.$emit('input', this.valueInner)
      this.$emit('change', this.valueInner)
    },
    cancelarEdicao() {
      this.$emit('cancelar', this.value)
      this.abrirCalendario = false
    },
    formatarInputPara(calendar) {
      const day = this.valueInner.substr(0, this.valueInner.indexOf('/'))
      const month = this.valueInner.substr(3, this.valueInner.indexOf('/'))
      const year = this.valueInner.slice(-4)

      return calendar ? `${month}-${day}-${year}` : `${year}-${month}-${day}`
    },
    mostrarCalendario() {
      this.abrirCalendario = true
      this.focus()
    },
    focus() {
      this.$nextTick(() => {
        if (this.$refs.inputcalendario) {
          this.$refs.inputcalendario.$el.focus()
          this.$refs.inputcalendario.$el.select()
        }
      })
    },
  },
}
</script>
